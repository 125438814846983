import axios from '@/axios.js'

export default {
  fetchFaqs ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/faq')
        .then(({data: response}) => {
          commit('SET_FAQS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchFaq (context, faqId) {
    return new Promise((resolve, reject) => {
      axios.get(`/faq/${faqId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeFaq (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/faq', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateFaq (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/faq/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyFaq ({ commit }, faqId) {
    return new Promise((resolve, reject) => {
      axios.delete(`faq/${faqId}`)
        .then((response) => {
          commit('REMOVE_RECORD', faqId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
