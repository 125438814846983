import state from './moduleFaqManagementState.js'
import mutations from './moduleFaqManagementMutations.js'
import actions from './moduleFaqManagementActions.js'
import getters from './moduleFaqManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

